import React from "react";
import CollectionSelectorGrid from "./Components/CollectionSelectorGrid";
import CollectionSelectorRow from "./Components/CollectionSelectorRow";

type Props = {
    layout: 'row' | 'grid';
}

const CollectionSelector: React.FC<Props> = ({ layout }) => {

    return (
        <>
            {layout === 'grid' ? <CollectionSelectorGrid /> : <CollectionSelectorRow />}
        </>
    );
}

export default CollectionSelector;