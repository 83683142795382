import { Breadcrumbs, Typography } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
    activeCollection?: any;
    activeProduct?: any;
}

const SidebarBreadcrumbs: React.FC<Props> = ({ activeCollection, activeProduct }) => {
    const { breadcrumbsLink } = useStyles();

    return (
        <>
        {activeCollection ? activeProduct ?
                <Breadcrumbs aria-label="breadcrumb" sx={{padding: 3}}>
                    <Link to="/katalog" className={breadcrumbsLink}>Katalog</Link>
                    <Link to={`/katalog/${activeCollection.slug}`} className={breadcrumbsLink}>{activeCollection.name}</Link>
                    <Typography color="text.primary" variant='body2'>{activeProduct.name}</Typography>
                </Breadcrumbs> :
                <Breadcrumbs aria-label="breadcrumb" sx={{padding: 3}}>
                    <Link to="/" className={breadcrumbsLink}>Naslovna</Link>
                    <Link to="/katalog" className={breadcrumbsLink}>Katalog</Link>
                    <Typography color="text.primary" variant='body2'>{activeCollection.name}</Typography>
                </Breadcrumbs> :
                <Breadcrumbs aria-label="breadcrumb" sx={{padding: 3}}>
                    <Link to="/" className={breadcrumbsLink}>Naslovna</Link>
                    <Typography color="text.primary" variant='body2'>Katalog</Typography>
                </Breadcrumbs>}
        </>
    );
}

const useStyles = makeStyles(() => ({
    breadcrumbsLink: {
        fontSize: 14,
        textDecoration: 'none',
        color: '#333',
        transition: 'color .2s linear',
        '&:hover': {
            color: '#0164ae'
        }
    },
}));

export default SidebarBreadcrumbs;