import { Container, Typography, Box} from "@mui/material";
import {makeStyles} from "@mui/styles";

type Props = {
    minHeight?: number;
    backgroundImage?: string;
    headline: string;
    alignX?: 'center' | 'start' | 'end';
    alignY?: 'center' | 'end';

}

const Featured: React.FC<Props> = ({minHeight, backgroundImage = '', headline, alignX = 'center', alignY = 'center'}) => {
    const { container, headlineText } = useStyles();

    const alignVertical = alignY === 'end' ? 'flex-end' : 'center';
    const alignHorizontal = alignX === 'end' ? 'flex-end' : alignX === 'start' ? 'flex-start' : 'center';


    return (
        <Box className={container} sx={{ minHeight: minHeight, backgroundImage: backgroundImage, justifyContent: alignVertical, alignItems: alignHorizontal}}>
            <Container maxWidth='xl'>
                <Box sx={{display: 'flex'}}>
                    <Typography variant='h3' component='h1' className={headlineText}>{headline}</Typography>
                </Box>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        boxSizing: 'border-box',
        backgroundColor: '#ffffff',
        backgroundImage: 'linear-gradient(150deg, #ffffff 0%, #d7e1ec 74%)',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0'
    },
    headlineText: {
        backgroundColor: '#fff',
        padding: '10px 20px',
        color: '#333'
    }
}));

export default Featured;