import {Box, IconButton, InputAdornment, Modal, TextField } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, toggleSearch } from './../../store/search'
import {Search as SearchIcon, Close as CloseIcon} from '@mui/icons-material';
import SearchResultsProduct from "./SearchResultsProduct";

type Props = {
    activeCollection?: any;
    activeProduct?: any;
}

const SearchResults: React.FC<Props> = ({ activeCollection, activeProduct }) => {
    const { searchResultsContainer, searchTextField, searchResultsList, modalContainer } = useStyles();
    const dispatch = useDispatch();
    const [searchVal, setSearchVal] = useState<string>('');
    const searchOpened = useSelector((state: any) => state.search.opened);
    const products = useSelector((state: any) => state.products.products);
    const [matches, setMatches] = useState<any>([]);

    const handleSearch = (val: string) => {
        setSearchVal(val);
        dispatch(updateSearch(val));
        if (val !== '') {
            const newMatches = products.filter((product: any) => product.name.toUpperCase().indexOf(val.toUpperCase()) !== -1);
            setMatches(newMatches);
        } else setMatches([]);
    }

    return (
        <Modal
            open={searchOpened}
            onClose={() => {
                dispatch(updateSearch(''));
                dispatch(toggleSearch());
            }}
            className={modalContainer}
        ><>
            <Box className={searchResultsContainer}>
                <TextField
                    className={searchTextField}
                    autoFocus
                    fullWidth
                    onChange={(e: any) => handleSearch(e.target.value)}
                    value={searchVal}
                    placeholder='Pretraga proizvoda...'
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    dispatch(updateSearch(''));
                                                    dispatch(toggleSearch());
                                                }}
                                                edge="end"
                                                size="large">
                                                <CloseIcon/>
                                            </IconButton>
                                        </InputAdornment>,
                        style: {
                            padding: '8px 10px',
                            fontSize: 18,
                        }
                    }}
                    />
            </Box>
            {matches.length ?
            <Box className={searchResultsList}>
                {matches.map((match: any, index: number) => (
                    <SearchResultsProduct product={match} key={match.slug + index} />
                ))}
            </Box> : <></>}</>
        </Modal>
    );
}

const useStyles = makeStyles(() => ({
    modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    searchResultsContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 0 8px',
        width: '100%'
    },
    searchTextField: {
        borderRadius: 5,
        backgroundColor: '#fff',
        width: '100%',
        maxWidth: 900,
    },
    searchResultsList: {
        backgroundColor: '#fff',
        borderRadius: 5,
        width: '100%',
        maxWidth: 900,
        maxHeight: 'calc(100vh - 150px)',
        overflowY: 'auto'
    }
}));

export default SearchResults;