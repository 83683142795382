import {Box, Container, Divider, Grid } from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import SidebarBreadcrumbs from "../../Components/Common/Breadcrumbs";
import Sidebar from "./Components/Sidebar";
import Product from "./Components/Product";
import { useSelector } from "react-redux";
import CollectionSelector from "../Homepage/Components/CollectionSelector/CollectionSelector";
import { Helmet } from "react-helmet";


const Collections = () => {
    const products = useSelector((state: any) => state.products.products);
    const collections = useSelector((state: any) => state.products.collections);
    let { collection, product } = useParams();
    const scrollRef = useRef(null);
    const [activeCollection, setActiveCollection] = useState<any>();
    const [activeCollectionProducts, setActiveCollectionProducts] = useState<any>();
    const [activeProduct, setActiveProduct] = useState<any>();

    useEffect(() => {
        if (collection) {
            const chosenSelection = collections.find((item: any) => item.slug === collection);
            const chosenSelectionproducts = products.filter((item: any) => item.collection === chosenSelection.id);
            setActiveCollection(chosenSelection);
            setActiveCollectionProducts([...chosenSelectionproducts]);
        } else {
            setActiveCollection(undefined);
            setActiveCollectionProducts(undefined);
            setActiveProduct(undefined);
        }
        if (product) {
            const chosenProduct = products.find((item: any) => item.slug === product);
            setActiveProduct(chosenProduct);
        } else {
            setActiveProduct(undefined)
        }
    }, [collection, product, collections, products]);

    useEffect(() => {
            setTimeout(() => {
                // @ts-ignore
                scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
            }, 100);
    }, [collection])

    const getHelmetTitle = () => {
        if (activeCollection) {
            return `DespoGS - ${activeCollection.name}`
        } else return 'DespoGS - Katalog'
    }

    const getHelmetURL = () => {
        if (activeCollection) {
            return `https://despogs.com/katalog/${activeCollection.slug}`
        } else return 'https://despogs.com/katalog'
    }

    return (
        <Container maxWidth='xl' disableGutters>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getHelmetTitle()}</title>
                <link rel="canonical" href={getHelmetURL()} />
            </Helmet>
            <div ref={scrollRef} />
            <Grid container spacing={3} sx={{ width: '100%'}}>
                {activeCollection ? <>
                <Grid item xs={3}>
                    <Box sx={{ backgroundColor: '#fff', marginBottom: 0, minHeight: '100vh', boxShadow: "rgba(17, 17, 26, .05) 0 1px 0, rgba(17, 17, 26, .1) 0 0 12px;", borderRadius: .5}}>
                        <SidebarBreadcrumbs
                            activeCollection={activeCollection}
                            activeProduct={activeProduct} />
                        <Divider />
                        <Sidebar
                            collections={collections}
                            products={products}
                            activeCollection={activeCollection}
                            activeProduct={activeProduct}/>
                    </Box>
                </Grid>
                <Grid item xs={9} sx={{ position: 'relative', height: '100%', paddingTop: '48px !important'}}>
                    {activeCollectionProducts.map((item: any, index: number) => {
                        return <Product product={item} key={item.slug} />
                    })}
                </Grid></> : <Grid item sx={{ml: 3}}>
                    <CollectionSelector layout='grid' /></Grid>}
            </Grid>
        </Container>
    );
}

export default Collections;