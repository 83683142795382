import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React, {useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Featured from "../../Components/Common/Featured";
import ContactInfo from "../Contact/Components/Info";

const About = () => {
    const scrollRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
    });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DespoGS - O Nama</title>
                <link rel="canonical" href="https://despogs.com/o-nama" />
            </Helmet>
            <div ref={scrollRef} />
            <Featured minHeight={300} headline='O Nama' alignY='end' backgroundImage='url("/images/bg/vinkle-2.jpg")'/>
            <Box sx={{backgroundColor: '#fff'}}>
                <Container maxWidth='xl' sx={{ py: 8}}>
                    <Grid container spacing={4}>
                        <Grid item xs={4} sx={{display: 'flex'}}>
                            <ContactInfo />
                        </Grid>
                        <Grid item xs={8}>
                            <Box sx={{mb: 4}}>
                                <Typography variant='h4' component='h1'>NOVO! Usluga brizganja plastike</Typography>
                                <Typography variant='body1' sx={{mt: 3}}>Uslužno brizganje plastike do 100 grama na <b>ARBURG 320C Golden Edition</b>. Dugogodišnje iskustvo u radu sa polipropilenom, polietilenom, PVC-om, Bum...</Typography>
                            </Box>
                            <Box>
                                <Typography variant='h4' component='h1'>Istorijat</Typography>
                                <Typography variant='body1' sx={{my: 3}}>Firma Despo-GS d.o.o. osnovana je 1998. godine. <u>Osnovna delatnost je proizvodnja i veleprodaja okova za nameštaj i stolariju</u>. Svojim kupcima nudimo vrhunski kvalitet, korektne cene, veliki lager i brzu isporuku robe na adresu. Kvalitet i brojne zadovoljne mušterije su naša najbolja preporuka.</Typography>
                                <Typography variant='body1'>Za dodatne informacije, porudžbine i zahteve <Link href='/kontakt/'>kontaktirajte nas</Link> i uverite se u kvalitet naših proizvoda i usluga !</Typography>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default About;