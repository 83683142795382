import React, { useEffect, useRef } from "react";
import {Box, Container, Divider, Grid } from "@mui/material";
import ContactMap from "./Components/Map";
import ContactInfo from "./Components/Info";
import ContactForm from "./Components/Form";
import { Helmet } from "react-helmet";

const Contact = () => {
    const scrollRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
    });
    
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DespoGS - Kontakt</title>
                <link rel="canonical" href="https://despogs.com/kontakt" />
            </Helmet>
            <div ref={scrollRef} />
            <ContactMap />
            <Box sx={{background: '#fff'}}>
                <Divider />
            <Container maxWidth='xl' sx={{ py: 8}}>
                <Grid container spacing={4}>
                    <Grid item xs={4} sx={{display: 'flex'}}>
                        <ContactInfo />
                    </Grid>
                    <Grid item xs={8}>
                        <ContactForm />
                    </Grid>
                </Grid>
            </Container>
            </Box>
        </>
    );
}

export default Contact;