import { createSlice } from '@reduxjs/toolkit'

export const products = createSlice({
    name: 'products',
    initialState: {
        products: [],
        collections: [],
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setCollections: (state, action) => {
            state.collections = action.payload;
        }
    },
})

export const { setProducts, setCollections } = products.actions

export default products.reducer;