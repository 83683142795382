import {Box, Chip, List, ListItem, ListItemButton, Typography } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
    collections: any;
    products: any;
    activeProduct: any;
    activeCollection: any;
}

const CollectionSidebar: React.FC<Props> = ({ collections, products, activeProduct, activeCollection }) => {
    const { collectionLink, sidebarList } = useStyles();

    return (
        <Box className={sidebarList}>
        <List>
            {collections.map((item: any, index: number) => {
                const productCount = products.filter((product: any) => product.collection === item.id);

                return (
                    <ListItem disablePadding key={`${item?.slug}_${index}`} sx={{ ...(activeCollection?.slug === item.slug && {backgroundColor: '#e4f3ff'})}}>
                        <Link to={`/katalog/${item.slug}`} className={collectionLink}>
                            <ListItemButton sx={{padding: '10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography variant='body1'>{item.name}</Typography>
                                <Chip size="small" label={productCount.length} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                )
            })}
        </List>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    sidebarList: {
        paddingBottom: '30px',
    },
    collectionLink: {
        width: '100%',
        textDecoration: 'none',
        color: '#333',
        //textTransform: 'capitalize',
        '&:hover': {
            fontWeight: 'bold'
        }
    },
}));

export default CollectionSidebar;