import React from "react";
import {Box, Link, List, ListItem, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    contactInfo: {
        borderRadius: '5px',
        border: '1px solid #ddd',
        padding: 2,
        width: '100%'
    },
    contactIcon: {
        width: 40,
        height: 40,
        border: '1px solid #ddd',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const ContactInfo = () => {
    const { contactInfo, contactIcon } = useStyles();

    return (
        <Box className={contactInfo}>
            <List sx={{py: 0}}>
                <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 2}}>
                    <Box className={contactIcon}>
                        <LocationOnIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body1'><b>Proizvodnja i Veleprodaja</b></Typography>
                        <Typography variant='body2'>Branka Radičevića 7<br/> 21400 Bačka Palanka</Typography>
                    </Box>
                </ListItem>
                <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 2}}>
                    <Box className={contactIcon}>
                        <PhoneIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body1'><b>Telefon</b></Typography>
                        <Link href="tel:+381216047492" variant='body2' sx={{textDecoration: 'none'}}>+381(21)-60-47-492</Link>
                        <Link href="tel:+381642219456" variant='body2' sx={{textDecoration: 'none'}}>+381(64)-221-94-56</Link>
                    </Box>
                </ListItem>
                <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 2}}>
                    <Box className={contactIcon}>
                        <AccessTimeIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body1'><b>Radno Vreme</b></Typography>
                        <Typography variant='body2'>08-16h<br />Ponedeljak-Subota</Typography>
                    </Box>
                </ListItem>
                <ListItem sx={{ display: 'flex', alignItems: 'center', mb: 2}}>
                    <Box className={contactIcon}>
                        <EmailIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body1'><b>Email Adresa</b></Typography>
                        <Link href="mailto:despogs@gmail.com" variant='body2' sx={{textDecoration: 'none'}}>despogs@gmail.com</Link>
                    </Box>
                </ListItem>
            </List>
        </Box>
    );
}

export default ContactInfo;