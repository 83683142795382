import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Box, Container, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import CollectionItem from "../../../../../Components/Collection/CollectionItem/CollectionItem";
import { useSelector } from "react-redux";


const CollectionSelectorRow = () => {
    const collections = useSelector((state: any) => state.products.collections);
    const { swiperContainer, swiperSlide, collectionContainer } = useStyles();

    return (
        <Box className={collectionContainer}>
            <Container maxWidth='xl' sx={{pt: 2, pb: 5}}>
                <Typography variant='h5' component='h3' sx={{my: 4}}>Proizvodni i prodajni program</Typography>
                <Swiper
                    slidesPerView='auto'
                    spaceBetween={0}
                    modules={[Navigation, Scrollbar]}
                    loop
                    navigation
                    scrollbar={{draggable: true}}
                    pagination={{clickable: true}}
                    className={swiperContainer}>
                    {
                        collections.map((item: any, index: number) => {
                            return (
                                <SwiperSlide className={swiperSlide} key={index}>
                                    <CollectionItem product={item} maxWidth={150} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    collectionContainer: {
      backgroundColor: '#fff'
    },
    swiperContainer: {
        width: '100%',
    },
    swiperSlide: {
        width: 200,
        padding: '0 15px'
    },
}));

export default CollectionSelectorRow;