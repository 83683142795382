import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import React from "react";

type Props = {
    product: any;
    maxWidth?: number;
}

const CollectionItem: React.FC<Props> = ({product, maxWidth}) => {
    const { collectionLink, collectionProduct, collectionTitle } = useStyles();
    const productImage = product.images.length ? `url("images/products/despo/${product.images[0]}")` : `url("images/products/placeholder/products/product-gridSlim.svg")`;

    return (
        <Link to={`/katalog/${product.slug}`} className={collectionLink}>
            <Box className={collectionProduct} sx={{backgroundImage: productImage, maxWidth: maxWidth}}></Box>
            <Typography variant='body1' className={collectionTitle}>{product.name}</Typography>
        </Link>
    );
}

const useStyles = makeStyles(() => ({
    collectionLink: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    collectionProduct: {
        width: '100%',
        borderRadius: '50%',
        border: '1px solid #f5f5f5',
        backgroundPosition: 'center',
        flex: '1 0 auto',
        aspectRatio: '1 / 1',
        backgroundColor: '#fff',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        transition: 'all .2s linear',
        marginBottom: 0,
        "&:hover": {
            backgroundSize: '110% auto',
            boxShadow: "rgba(17, 17, 26, .05) 0 1px 0, rgba(17, 17, 26, .1) 0 0 12px;",
        }
    },
    collectionTitle: {
        textTransform: 'capitalize',
        padding: '10px 0',
        textDecoration: 'none',
        textAlign: 'center'
    }
}));

export default CollectionItem;