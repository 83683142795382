import React, { useEffect, useRef } from "react";
import { Divider} from "@mui/material";
import CollectionSelector from "./Components/CollectionSelector/CollectionSelector";
import SwiperFeatured from "../../Components/Common/SwiperFeatured";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Highlights from "./Components/Highlights/Highlights";

const swiperSettings = [
    {
        header: 'Proizvodnja i prodaja okova',
        subheader: 'Preko 20 godina uspešnog poslovanja',
        bg: 'bg/baner-new-2.jpg',
        link: '/katalog/',
        linkText: 'Katalog Proizvoda'
    },
    {
        header: 'Preko 20 godina sa vama',
        subheader: 'Vrhunski kvalitet, korektne cene, veliki lager i brza isporuka',
        bg: 'bg/vinkle-2.jpg',
        link: '/o-nama/',
    },
    {
        header: 'Dostava na Vašu adresu!',
        subheader: 'Poručite telefonom ili putem email-a.',
        bg: 'bg/delivery.jpg',
        link: '/katalog/',
        linkText: 'Katalog Proizvoda'
    }
]

const Homepage = () => {
    const collections = useSelector((state: any) => state.products.collections);
    const scrollRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
    });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>DespoGS - Proizvodnja i Prodaja Okova</title>
                <link rel="canonical" href="https://despogs.com/" />
            </Helmet>
            <div ref={scrollRef} />
            <SwiperFeatured minHeight={500} slides={swiperSettings}/>
                        <Highlights />
                        <Divider />


            {(collections && collections.length) &&
                    <CollectionSelector layout='row' />
            }
        </>
    );
}



export default Homepage;
