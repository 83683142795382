import React from "react";
import {Box, Button, TextField, Typography } from "@mui/material";
import { useForm, ValidationError } from '@formspree/react';

const ContactForm = () => {
    const [state, handleSubmit] = useForm("myyvzgby");

    if (state.succeeded) {
        return <Typography variant='h6'>Hvala! Vaša poruka je poslata.<br/>Kontaktiraćemo Vas u najkraćem mogućem roku!</Typography>;
    }

    return (
            <form onSubmit={handleSubmit}>
            <Typography variant='h5'>Pošaljite nam poruku:</Typography>
            <Box sx={{pt: 3}}>
                <TextField
                    fullWidth
                    id="name"
                    name='name'
                    type='text'
                    label="Vaše ime"
                    variant="outlined"
                    sx={{mb: 2}}
                    />
                <TextField
                    fullWidth
                    id="email"
                    type='email'
                    name='email'
                    label="Vaš email"
                    variant="outlined"
                    sx={{mb: 2}}/>
                <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                />
                <TextField
                    fullWidth
                    id="comment"
                    name='comment'
                    label="Poruka"
                    variant="outlined"
                    multiline
                    rows={4}/>
                <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                />
                <Button type='submit' disabled={state.submitting} variant="contained" size="large" sx={{mt: 2, borderRadius: 25, px: 5}}>Potvrdi</Button>
            </Box>
            </form>
    );
}

export default ContactForm;