import {Container, Grid, Typography, Box, Divider} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { useSelector } from "react-redux";
import FooterInfo from "./Components/FooterInfo";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
    const { footerContainer, collectionLink } = useStyles();
    const collections = useSelector((state: any) => state.products.collections);

    return (
        <Box className={footerContainer}>
            <Divider />
            <Container maxWidth='xl' sx={{py: 3}}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <FooterInfo />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1' sx={{mb: 3}}>
                            <b>Katalog Proizvoda</b>
                        </Typography>
                        <Grid container>
                            {collections.map((item: any, index: number) => { return (
                                    <Grid item md={2} xs={6} sx={{mb: 1}} key={item.slug}>
                                        <Link to={`/katalog/${item.slug}`} className={collectionLink}>
                                            {item.name}
                                        </Link>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Divider />
            <Container maxWidth='xl' disableGutters>
                <Grid container sx={{py: 2}}>
                    <Grid item xs={12} sx={{px: 3}}>
                        <Typography variant='body2' sx={{my: 0}} color='#999'>Copyright © 2022 DespoGS doo</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    footerContainer: {
        backgroundColor: "#f5f5f5",
        position: 'relative'
    },
    collectionLink: {
        color: '#333',
        textDecoration: 'none',
        transition: 'color .2s linear',
        fontSize: 14,
        '&:hover': {
            color: '#0e50a3'
        }
    }
}));

export default Footer;