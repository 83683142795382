import React from "react";
import {Box, Grid, Link, Typography } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    contactInfo: {
        borderRadius: '5px',
        //border: '1px solid #ddd',
        padding: 1,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: 'rgb(17 17 26 / 5%) 0 1px 0, rgb(17 17 26 / 10%) 0 0 12px',
        "@media (max-width: 768px)": {
            padding: '8px 12px',
            width: 'auto'
        },
    },
    contactIcon: {
        width: 40,
        height: 40,
        border: '1px solid #ddd',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //backgroundColor: '#fff'
    },
    footerInfoListItem: {
        display: 'flex',
        alignItems: 'flex-start !important',
        padding: 20,
        "@media (max-width: 768px)": {
            padding: 0,
            margin: '8px 0 !important'
        }
    }
}));

const FooterInfo = () => {
    const { contactInfo, contactIcon, footerInfoListItem } = useStyles();

    return (
        <Box className={contactInfo}>
            <Grid container>
                <Grid item md={3} className={footerInfoListItem}>
                    <Box className={contactIcon}>
                        <LocationOnIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body2'>Branka Radičevića 7<br/> 21400 Bačka Palanka</Typography>
                    </Box>
                </Grid>
                <Grid item md={3} className={footerInfoListItem}>
                    <Box className={contactIcon}>
                        <PhoneIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Link href="tel:+381216047492" variant='body2' sx={{textDecoration: 'none'}}>+381(21)-60-47-492</Link>
                        <Link href="tel:+381642219456" variant='body2' sx={{textDecoration: 'none'}}>+381(64)-221-94-56</Link>
                    </Box>
                </Grid>
                <Grid item md={3} className={footerInfoListItem}>
                    <Box className={contactIcon}>
                        <AccessTimeIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body2'>08 - 16h<br />Ponedeljak - Subota</Typography>
                    </Box>
                </Grid>
                <Grid item md={3} className={footerInfoListItem}>
                    <Box className={contactIcon}>
                        <EmailIcon />
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 2}}>
                        <Typography variant='body2'>Email adresa</Typography>
                        <Link href="mailto:despogs@gmail.com" variant='body2' sx={{textDecoration: 'none'}}>despogs@gmail.com</Link>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default FooterInfo;