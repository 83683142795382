import { createSlice } from '@reduxjs/toolkit'

export const search = createSlice({
    name: 'search',
    initialState: {
        value: '',
        opened: false,
    },
    reducers: {
        toggleSearch: (state) => {
            state.opened = !state.opened;
        },
        updateSearch: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { updateSearch, toggleSearch } = search.actions

export default search.reducer;