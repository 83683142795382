import React from "react";
import { Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";


const CollectionSelectorGrid = () => {
    const collections = useSelector((state: any) => state.products.collections);
    const { collectionBox, collectionTitle, collectionImage } = useStyles();


    return (
        <Grid container spacing={2.5} sx={{py: 3}}>
            {collections && collections.map((item: any, index: number) => {
                    const image = item.images?.length ? `/images/products/despo/${item.images[0]}` : `/images/products/placeholder/brand-bg-white.svg`;
                    return (
                        <Grid item xs={2.4} key={`${item.slug}_${index}`}>
                            <Link to={`/katalog/${item.slug}`} className={collectionBox}>
                                <img src={image} alt={item.name} className={collectionImage} />
                                <Typography variant='h6' className={collectionTitle}>{item.name}</Typography>
                            </Link>
                        </Grid>
                    )
                })
            }
        </Grid>
    );
}

const useStyles = makeStyles(() => ({
    collectionBox: {
        boxShadow: "rgba(17, 17, 26, .05) 0 1px 0, rgba(17, 17, 26, .1) 0 0 8px;",
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 5,
        textDecoration: 'none',
        color: 'inherit',
        textAlign: 'center',
        height: '100%'
    },
    collectionTitle: {
        textTransform: 'capitalize',
        padding: '10px 0',
        textDecoration: 'none',
    },
    collectionImage: {
        maxWidth: '100%',
    },
}));

export default CollectionSelectorGrid;