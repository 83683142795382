import { Grid, Typography } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import {useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateSearch, toggleSearch } from './../../store/search';

type Props = {
    product: any;
}

const SearchResultsProduct: React.FC<Props> = ({ product }) => {
    const { productImage, tags, collectionLink, productItem } = useStyles();
    const dispatch = useDispatch();
    const collections = useSelector((state: any) => state.products.collections);

    const img = product.images.length ? `/images/products/despo/${product.images[0]}` : `/images/products/placeholder/products/product-gridSlim.svg`;
    const collection = collections.find((collection: any) => collection.id === product.collection);

    return (
        <Link to={`/katalog/${collection.slug}`} className={collectionLink} onClick={() => {
            dispatch(updateSearch(''));
            dispatch(toggleSearch())
        }}>
            <Grid container className={productItem} sx={{ borderBottom: '1px solid #ddd', borderRadius: .5, overflow: 'hidden'}}>
                <Grid item xs={3} sx={{ justifyContent: 'center', display: 'flex', padding: 1}}>
                    <img src={img} alt={product.name} className={productImage} />
                </Grid>
                <Grid item xs={9} sx={{ padding: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <Typography variant='h6' component='h1'>
                        {product.name}
                    </Typography>
                    <Typography variant="body2" component='p' sx={{mt: 1}}>
                        Šifra artikla: {product.sku}
                    </Typography>
                    {product.variations?.length && <>
                        <ul className={tags}>
                            {product.variations.map((variation:any) => {
                                return <li>{variation.name}</li>
                            })}
                        </ul>
                    </>}
                </Grid>
            </Grid>
        </Link>
    );
}

const useStyles = makeStyles(() => ({
    productImage: {
        maxHeight: 170,
        borderRadius: 5,
        maxWidth: '100%'
    },
    collectionLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
    tags: {
        listStyleType: 'none',
        padding: 0,
        margin: '10px 0 0',
        '& > li': {
            display: 'inline-block',
            padding: '5px 10px',
            border: '1px solid #0065b2',
            color: '#0065b2',
            borderRadius: 15,
            marginRight: 5,
            marginBottom: 5,
            fontSize: 14,
        }
    },
    productItem: {
        transition: 'all .2s linear',
        '&:hover': {
            backgroundColor: '#f8f8f8'
        }
    }
}));

export default SearchResultsProduct;