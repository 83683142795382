import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { toggleSearch } from './../../store/search'
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Link as MUILink, Typography, Toolbar, ListItemText, ListItemButton, ListItem, List, IconButton, Drawer, Divider, Grid, Button, Container } from '@mui/material';
import {makeStyles} from "@mui/styles";
import { Link, NavLink } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import Search from '@mui/icons-material/Search';


const siteLogo = '/images/logo-3.png';
const katalog = '/images/despogs-katalog-2023.pdf';

const drawerWidth = 240;
const navItems = [
    {
        label: 'Katalog Proizvoda',
        link: '/katalog'
    },
    {
        label: 'O Nama',
        link: '/o-nama'
    },
    {
        label: 'Kontakt',
        link: '/kontakt'
    }
];

const useStyles = makeStyles(() => ({
    logoImg: {
        width: 270,
        "@media (max-width: 768px)": {
            width: 200,
        },
    },
    navbar: {
        backgroundColor: '#fff !important',
        boxShadow: "rgba(17, 17, 26, .05) 0 1px 0, rgba(17, 17, 26, .1) 0 0 12px !important"
    },
    navbarLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        height: '100%',
        padding: '0 15px',
        fontSize: 16,
        color: '#333',
        borderBottom: '2px solid #fff',
        opacity: .8,
        transition: 'all .2s linear',
        '&:hover': {
            borderBottomColor: '#333',
            opacity: 1
        }
    },
    activeLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        height: '100%',
        padding: '0 15px',
        fontSize: 16,
        color: '#333',
        borderBottom: '2px solid #333',
        opacity: 1,
        "@media (max-width: 768px)": {
            width: '100%'
        },
    },
    pdfButton: {
        borderRadius: '25px !important',
        textDecoration: 'none !important',
        padding: '8px 20px !important',
        whiteSpace: 'nowrap',
        backgroundColor: '#e91f2f !important',
        "@media (max-width: 768px)": {
            display: 'none !important'
        },
    },
    logoMobileImg: {
        width: '90%',
        margin: '8px 0',
    },
    headerControls: {
        display: 'flex',
        alignItems: 'center',
    },
    headerLinks: {
        justifyContent: 'space-between',
        alignItems: 'center',
        "@media (max-width: 768px)": {
            justifyContent: 'flex-end'
        },
    },
    promoBar: {
        backgroundColor: '#2a86fa',
        textAlign: 'center',
    }
}));

const Header = (props: any) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    const { logoImg, logoMobileImg, navbar, promoBar, headerControls, headerLinks, navbarLink, activeLink, pdfButton } = useStyles();
    const dispatch = useDispatch()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <img src={siteLogo} className={logoMobileImg} alt='DespoGS Logo' width='270' height='50'/>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <NavLink to={item.link} className={({isActive}) => isActive ? activeLink : navbarLink}>
                            <ListItemButton sx={{ width: '100%' }}>
                               <ListItemText primary={item.label} />
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                ))}
                <ListItem sx={{mt: 4}}>
                    <MUILink href={katalog} target="_blank" rel="noreferrer" underline="none" sx={{mr: 3}}>
                        <Button variant='contained' size="small" startIcon={<DownloadIcon />} className={pdfButton}>PDF Katalog</Button>
                    </MUILink>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" className={navbar}>
                <Box className={promoBar}>
                    <Container maxWidth='xl'>
                        <Typography variant='body1'>Robu možete poručiti i putem telefona na broj +381-64-22-19-456</Typography>
                    </Container>
                </Box>
                <Container maxWidth='xl' disableGutters>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to='/'><img src={siteLogo} className={logoImg} alt='Despo GS Logo'/></Link>
                    <Grid container className={headerLinks} sx={{flexWrap: 'nowrap'}}>
                        <Grid container justifyContent='flex-start' sx={{ display: { xs: 'none', sm: 'flex' }, ml: 10, height: 100}}>
                            {navItems.map((item, index) => (
                                <Grid key={index} item sx={{mr: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <NavLink to={item.link} className={({isActive}) => isActive ? activeLink : navbarLink}>
                                        {item.label}
                                    </NavLink>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item className={headerControls}>
                            <MUILink href={katalog} target="_blank" rel="noreferrer" underline="none" sx={{mr: 3}}>
                                <Button variant='contained' size="medium" startIcon={<DownloadIcon />} className={pdfButton}>PDF Katalog</Button>
                            </MUILink>
                            <IconButton aria-label='Pretraga...' onClick={() => {
                                dispatch(toggleSearch())
                            }} size='large'>
                                <Search />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                </Container>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default Header;
