import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from "./Components/Common/Header";
import Homepage from "./Pages/Homepage/Homepage";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Collections from "./Pages/Collections/Collections";
import {Box } from "@mui/material";
import {makeStyles} from "@mui/styles"
import { useSelector, useDispatch } from 'react-redux'
import SearchResults from "./Components/Common/SearchResults";
import { setProducts, setCollections } from './store/products'
import Footer from "./Components/Common/Footer";

const App = () => {
    const { content } = useStyles();
    const searchOpened = useSelector((state: any) => state.search.opened)
    const dispatch = useDispatch();

    useEffect(() => {
        const products = require('./data/products.json');
        const collections = require('./data/collections.json');
        dispatch(setProducts(products));
        dispatch(setCollections(collections));
    }, [dispatch])
    
  return (
      <Router>
        <div>
          <Header />
            <Box className={content}>
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/katalog" element={<Collections />} />
                    <Route path="/katalog/:collection" element={<Collections />} />
                    <Route path="/katalog/:collection/:product" element={<Collections />} />
                    <Route path="/o-nama" element={<About />} />
                    <Route path="/kontakt" element={<Contact />} />
                </Routes>
            </Box>
            {searchOpened && <SearchResults />}
            <Footer />
        </div>
      </Router>
  );
}

const useStyles = makeStyles(() => ({
    content: {
        background: '#f3f4f6',
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        paddingTop: 124,
    }
}));

export default App;