import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Scrollbar, Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Button, Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { Link } from "react-router-dom";

type Props = {
    minHeight?: number;
    slides: any;
}

const SwiperFeatured: React.FC<Props> = ({minHeight, slides}) => {
    const { swiperContainer, swiperSlide, slideButtonContainer, slideButton, slideHeader, slideSubheader } = useStyles();

    return (
        <Swiper
            slidesPerView={1}
            loop
            modules={[Pagination, Scrollbar, Autoplay, Navigation]}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            scrollbar={{draggable: true}}
            pagination={{clickable: true}}
            style={{minHeight: minHeight}}
            className={swiperContainer}>
            {slides.map((slide: any, index: number) => {
                return (
                    <SwiperSlide className={swiperSlide} style={{ minHeight: minHeight, backgroundImage: `url("/images/${slide.bg}")` }} key={`slide_${index}`}>
                        <Container maxWidth='xl'>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h4' component='h1' sx={{mb: 1.5}} className={slideHeader}><b>{slide.header}</b></Typography>
                                    <div>
                                        <Typography variant='h6' component='span' className={slideSubheader}>{slide.subheader}</Typography>
                                    </div>
                                    {slide.link &&
                                        <Link to={slide.link} className={slideButtonContainer}>
                                            <Button variant='contained' sx={{mt: 5}} className={slideButton} size="large">{slide.linkText ? slide.linkText : 'Saznaj više'}</Button>
                                        </Link>
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    );
}

const useStyles = makeStyles(() => ({
    swiperContainer: {
        border: '1px solid #e5e7eb',
        //borderRadius: 8,
        backgroundColor: '#ffffff',
        backgroundImage: 'linear-gradient(150deg, #ffffff 0%, #d7e1ec 74%)',
    },
    swiperSlide: {
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    slideButtonContainer: {
        textDecoration: 'none',
    },
    slideButton: {
        padding: '10px 30px !important',
        borderRadius: '30px !important',
        fontWeight: '600 !important'
    },
    slideHeader: {
        backgroundColor: '#fff',
        padding: '10px 20px',
        fontSize: '40px !important'
    },
    slideSubheader: {
        backgroundColor: '#fff',
        padding: '10px 20px'
    }
}));

export default SwiperFeatured;