import React from "react";
import {Grid, Container, Typography} from "@mui/material";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import {makeStyles} from "@mui/styles";

const Highlights: React.FC = () => {
    const { highlightIcon } = useStyles();

    return (
        <Container maxWidth='xl' sx={{py: 3, backgroundColor: '#fff'}}>
            <Grid container spacing={3}>
                <Grid item sm={12} md={4}>
                    <Grid container alignItems='center'>
                        <Grid item className={highlightIcon}>
                            <PrecisionManufacturingIcon />
                        </Grid>
                        <Grid item sx={{ml: 2}}>
                            <Typography variant='h6' component='h2'>
                                <b>Proizvodnja okova i opreme</b>
                            </Typography>
                            <Typography variant='body1'>
                                Visok kvalitet i garancija
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Grid container alignItems='center'>
                        <Grid item className={highlightIcon}>
                            <LocalGroceryStoreIcon />
                        </Grid>
                        <Grid item sx={{ml: 2}}>
                            <Typography variant='h6' component='h2'>
                                <b>Veleprodaja svih vrsta okova</b>
                            </Typography>
                            <Typography variant='body1'>
                                Više od 1000 artikala u ponudi
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Grid container alignItems='center'>
                        <Grid item className={highlightIcon}>
                            <MicrowaveIcon />
                        </Grid>
                        <Grid item sx={{ml: 2}}>
                            <Typography variant='h6' component='h2'>
                                <b>Uslužno brizganje plastike</b>
                            </Typography>
                            <Typography variant='body1'>
                                Zagarantovan kvalitet
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

const useStyles = makeStyles(() => ({
    highlightIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 70,
        height: 70,
        borderRadius: '50%',
        border: '1px solid #333',
        '& > svg': {
            width: 32,
            height: 32
        }
    },
}));

export default Highlights;