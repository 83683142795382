import React from "react";
import {Box } from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    mapOuter: {
        position: 'relative',
        textAlign: 'right',
        height: 300,
        width: '100%',
        backgroundColor: '#f5f5f5'

    },
    gmapCanvas: {
        overflow: 'hidden',
        background: '#f5f5f5 !important',
        height: 300,
        width: '100%'
    }
}));

const ContactMap = () => {
    const { mapOuter, gmapCanvas } = useStyles();

    return (
        <Box className={mapOuter}>
            <Box className={gmapCanvas}>
                <iframe id="gmapCanvas"
                        width="100%"
                        title='DespoGS Adresa'
                        height={300}
                        src="https://maps.google.com/maps?q=branka%20radicevica%207,%20backa%20palanka&t=&z=15&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no"></iframe>
            </Box>
        </Box>
    );
}

export default ContactMap;