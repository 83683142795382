import {Divider, Grid, Typography } from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, { useState } from "react";

type Props = {
    product: any;
}

const Product: React.FC<Props> = ({ product }) => {
    const { productImage, tags, activeTag } = useStyles();
    const [productSku, setProductSku] = useState<string>(product.sku);
    const [variationName, setVariationName] = useState<string>('');
    const [activeVariation, setActiveVariation] = useState<number>(0);

    const img = product.images.length ? `/images/products/despo/${product.images[0]}` : `/images/products/placeholder/products/product-gridSlim.svg`;

    return (
        <>
            <Grid container sx={{ boxShadow: "rgba(17, 17, 26, .05) 0 1px 0, rgba(17, 17, 26, .1) 0 0 12px;", borderRadius: 2, overflow: 'hidden', mb: 3, alignItems: 'center', backgroundColor: '#fff'}}>
                <Grid item xs={4} sx={{justifyContent: 'center', display: 'flex', ...(!product.images.length && { backgroundColor: '#eaedef' }), ...(product.images.length && {backgroundColor: '#fff'}) }}>
                    <img src={img} alt={product.name} className={productImage} />
                </Grid>
                <Grid item xs={8} sx={{backgroundColor: '#fff', padding: 5}}>
                    <Typography variant='h5' component='h1'>
                        {product.name} {variationName && '- '+variationName}
                    </Typography>
                    <Typography variant="body1" component='p' sx={{mt: 1}}>
                        Šifra artikla: {productSku}
                    </Typography>
                    {product.text && <Typography variant="body1" component='p' sx={{mt: 1}}>
                        {product.text}
                    </Typography>}
                    {product.variations?.length && <>
                        <Divider sx={{my: 2}}/>
                        <ul className={tags}>
                            {product.variations.map((variation:any, index: number) => {
                                return <li key={`${variation.name}_${index}`} onClick={() => {
                                    setVariationName(variation.name);
                                    setProductSku(variation.sku);
                                    setActiveVariation(index);
                                }} className={activeVariation === index ? activeTag : ''}>{variation.name}</li>
                            })}
                        </ul>
                            </>}
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles(() => ({
    productImage: {
        maxWidth: '100%',
        maxHeight: 350,
    },
    tags: {
        listStyleType: 'none',
        padding: 0,
        margin: '0',
        '& > li': {
            display: 'inline-block',
            padding: '8px 12px',
            border: '1px solid #0065b2',
            color: '#0065b2',
            fontSize: 14,
            borderRadius: 25,
            marginRight: 8,
            marginBottom: 8,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#0065b2',
                color: '#fff'
            }
        }
    },
    activeTag: {
        backgroundColor: '#0065b2',
        color: '#fff !important'
    }
}));

export default Product;